<template>
  <el-main class="center-container-PL">
    <div class="user-info-container-PL">
      <div class="user-info-block-PL">
        <img src="../assets/img/personal_center/icon.png">
        <span class="user-name-style">{{ name }}</span>
      </div>
      <div class="user-info-block-PL" v-for="i in userInfo">
        <div style="width: 200px">
          <article class="user-phoneNum-style">{{ i.title }}</article>
          <input class="input-style-PL" :id="'input_'+i.id" v-model="i.content" @keyup.enter="closeEdit(i.id)"
                 :disabled="true"></input>
        </div>
        <button @click="edit_user_info(i.id)" :id="'editUserInfo_'+i.id" class="edit-action-PL button-style">
          <img src="../assets/img/personal_center/edit.png">
        </button>
      </div>
    </div>
    <div class="login-history-container">
      <div class="body-title-layout">
        <div class="divider-3"></div>
        <div class="body-preview-title-content-style">登录历史</div>
      </div>
      <el-scrollbar class="scrollbar-container-PL">
        <div class="login-history-items-layout" v-for="i in userLoginInfo" :key="i.id">
          <div class="login-info-word-style">{{ i.log_content }}</div>
          <div class="login-time-word-style">{{ i.create_time }}</div>
        </div>
      </el-scrollbar>
    </div>
  </el-main>
</template>

<script>
export default {
  name: "LoginHistory",
  methods: {
    edit_user_info(key) {
      let id_ = 'input_' + key;
      let elemInput = document.getElementById(id_);
      elemInput.style.borderBottomWidth = '1px';
      elemInput.style.borderBottomColor = '#089BAB';
      elemInput.disabled = false;
    },
    closeEdit(key) {
      let id_ = 'input_' + key;
      let elemInput = document.getElementById(id_);
      elemInput.style.borderBottomWidth = '0';
      elemInput.disabled = true;
    },

    getUserLogByUserId() {
      this.axios.get('/jeecg-boot/sys/log/logByUserId', {
        params: {
          userId: localStorage.getItem('userId')
        }
      }).then(res => {
        console.log(res.result)
      })
    }
  },
  data() {
    let userInfo = [
      {
        id: 1,
        title: '电话',
        content: '12589465874'
      },
      {
        id: 2,
        title: '所属院系',
        content: '医学院'
      }
    ]
    return {
      userLoginInfo: [],
      userInfo,
      name: '',
    }
  },
  mounted() {
    //  根据登录用户修改展示的用户信息
    this.userInfo[0].content = localStorage.getItem('phone')
    this.name = localStorage.getItem('userName')
  }
}
</script>

<style>
</style>
